import React from "react";
import { MdClose } from "react-icons/md";

export default function ContactAlert(props) {
  const alertMessage = props.alertMessage;
  const alertStatus = props.alertStatus;
  const closeAlert = props.closeAlert;
  return (
    <div className="alert-wrapper">
      <div className="alert-content-wrapper">
        <div className="alert-header">
          <h4 className="alert-heading">{alertStatus || `Error`}</h4>
          <MdClose className="close-icon" onClick={() => closeAlert()} />
        </div>
        <div className="alert-message">
          <p>
            {alertMessage ||
              `Hey you! Thank you for reaching out! If you are seeing this, something went wrong. Please contact us via phone.`}
          </p>
        </div>
      </div>
    </div>
  );
}
